import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import pack from '../../../package.json';

const h = window.location.host;
const tld = h.split('.').pop();
// eslint-disable-next-line no-nested-ternary
const environment = tld === 'com' ? 'production' : tld === 'local' ? 'dev' : 'other';

// console.warn(environment);

if (environment === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://c37fbca7679347fc8f2b95b0a9ba15c4@o749834.ingest.sentry.io/5791472',
    integrations: [
      new Integrations.BrowserTracing({
        tracePropagationTargets: ['sport-finder.com'],
      }),
    ],
    environment,
    // debug: environment === 'dev',
    release: `sportfinder@${pack.version}`,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.01,
  });
}
